import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Image from 'Components/Image';
import s from './CardEntrance.module.scss';

const CardEntrance = ({
    title,
    image,
    url,
    description,
    target,
    rel,
    attrTitle,
    extraClasses,
    isSmall,
}) => {

    const sanitizedTitle = sanitizeText(title);
    const sanitizedDescription = sanitizeText(description);

    const sizes = [
        '(min-width: 1440px) 428px',
        '(min-width: 768px) 33vw',
        '100vw',
    ];

    const classes = classNames(
        s['CardEntrance'],
        {[s['CardEntrance--Large']]: !isSmall},
        {[s['CardEntrance--Small']]: isSmall},
        {[s['CardEntrance--NoImage']]: _.isEmpty(image)},
        {[s['CardEntrance--HasImage']]: !_.isEmpty(image)},
        extraClasses.map((className) => [s[`CardEntrance--${_.upperFirst(className)}`]]),
    );

    return (
        <article className={classes}>
            <a
                className={s['CardEntrance__Link']}
                href={url}
                target={target}
                rel={rel}
                title={attrTitle}
            >
                <span
                    className={'sr-only'}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />
            </a>

            {!_.isEmpty(image) && !isSmall &&
                <div className={s['CardEntrance__Image']}>
                    <Image {...image} sizes={sizes} />
                </div>
            }

            <div className={s['CardEntrance__Container']}>
                <div className={s['CardEntrance__Button']}></div>
                <div className={s['CardEntrance__Content']}>
                    <h3
                        className={s['CardEntrance__Title']}
                        dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                    />
                    {!_.isEmpty(description) &&
                        <p className={s['CardEntrance__Text']}
                            dangerouslySetInnerHTML={{__html: sanitizedDescription}}
                        />
                    }
                </div>
            </div>
        </article>
    );
};

CardEntrance.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.object,
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
    rel: PropTypes.string,
    attrTitle: PropTypes.string,
    extraClasses: PropTypes.array,
    isSmall: PropTypes.bool,
};

CardEntrance.defaultProps = {
    title: '',
    image: {},
    description: '',
    url: '',
    target: null,
    rel: null,
    attrTitle: null,
    extraClasses: [],
    isSmall: false,
};

export default CardEntrance;
