/* global YT */
import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './Video.module.scss';

const Video = ({iframe, onPlayerReady, isBackground, className}) => {

    const videoRef = useRef();

    useEffect(() => {
        const loadYoutube = (videoId) => {
            const api = document.getElementById('youtube-api');
            if(!api) {
                const tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/player_api';
                tag.id = 'youtube-api';
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }

            document.addEventListener('youtube-ready', function() {
                onYouTubeReady(videoId, videoRef.current);
            });
        };

        const vimeoReady = (videoId) => {
            const VimeoPlayer = require('@vimeo/player');
            if(VimeoPlayer.default) {
                const player = new VimeoPlayer.default(videoId, {
                    responsive: true,
                });
                player.on('play', function(e) {
                    if(onPlayerReady) {
                        onPlayerReady(e, player, 'vimeo');
                    }
                });
            }
        };

        const onYouTubeReady = (videoId) => {
            const player = new YT.Player(videoId);
            player.addEventListener('onReady', function(e) {
                if(onPlayerReady) {
                    onPlayerReady(e, player, 'youtube');
                }
            });
        };

        const recalculateVideoBackground = (videoIframe) => {
            const width = videoIframe.width;
            const height = videoIframe.height;
            const vWidth = width / height * 100;
            const vHeight = height / width * 100;
            videoIframe.style.width = `${vWidth}vh`;
            videoIframe.style.height = `${vHeight}vw`;
        };

        if(videoRef.current) {
            const videoIframe = videoRef.current.getElementsByTagName('iframe')[0];
            if(videoIframe) {
                if(isBackground) {
                    recalculateVideoBackground(videoIframe);
                }

                const url = videoIframe.src;
                const videoId = `iframe-video-${Math.floor(Math.random() * 100)}`;
                videoIframe.id = videoId;

                if(url.includes('youtube')) {
                    loadYoutube(videoId);
                } else if(url.includes('vimeo')) {
                    vimeoReady(videoId);
                }
            }
        }
    }, []);

    const classes = classNames(
        className,
        'Video',
        {[s['Video--IsBackground']]: isBackground},
    );

    return (
        <div
            className={classes}
            ref={videoRef}
            dangerouslySetInnerHTML={{__html: iframe}}
        />
    );
};

Video.propTypes = {
    iframe: PropTypes.string.isRequired,
    isBackground: PropTypes.bool,
    onPlayerReady: PropTypes.func,
    className: PropTypes.string,
};

Video.defaultProps = {
    iframe: '',
    isBackground: false,
    onPlayerReady: null,
    className: '',
};

export default Video;
