import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTranslation} from 'next-i18next';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import PlayBorder from 'Assets/svg/playBorder.svg';
import Image from 'Components/Image';
import Video from 'Components/Video';
import Button from 'Components/Button';
import s from './Hero.module.scss';

const Hero = ({
    title,
    text,
    button,
    image,
    videoBg,
    videoModal,
    hasEntrances,
    isDarkMode,
    isCenter,
    isLarge,
}) => {
    const {t} = useTranslation();

    const [videoPlaying, setVideoPlaying] = useState(!_.isEmpty(videoBg));
    const [modalOpen, setModalOpen] = useState(false);

    const [videoBgPlayer, setVideoBgPlayer] = useState('');
    const [videoType, setVideoType] = useState('');

    useEffect(() => {
        if(!_.isEmpty(videoBgPlayer)) {
            if(videoType === 'youtube') {
                if(videoPlaying) {
                    videoBgPlayer.playVideo();
                } else {
                    videoBgPlayer.pauseVideo();
                }
            } else if(videoType === 'vimeo') {
                if(videoPlaying) {
                    videoBgPlayer.play();
                } else {
                    videoBgPlayer.pause();
                }
            }
        }
    }, [videoPlaying, videoType, videoBgPlayer]);

    const onVideoBgReady = (e, player, type) => {
        setVideoBgPlayer(player);
        setVideoType(type);
    };

    const sanitizedTitle = sanitizeText(title);
    const sanitizedText = sanitizeText(text);

    // Button should be red if background exists or doesn't have entrances
    const noBg = _.isEmpty(image) && _.isEmpty(videoBg);
    const buttonModifier = !noBg || !hasEntrances ? 'PrimaryLink' : 'TertiaryLink';
    const playModifier = isDarkMode ? 'Tertiary' : 'Secondary';

    const classes = classNames(
        s['Hero'],
        {[s['Hero--HasEntrances']]: hasEntrances},
        {[s['Hero--NoEntrances']]: !hasEntrances},
        {[s['Hero--Dark']]: isDarkMode},
        {[s['Hero--Light']]: !isDarkMode},
        {[s['Hero--Left']]: !isCenter},
        {[s['Hero--Center']]: isCenter},
        {[s['Hero--HasImage']]: !_.isEmpty(image)},
        {[s['Hero--HasVideo']]: !_.isEmpty(videoBg)},
        {[s['Hero--NoVideo']]: _.isEmpty(videoBg)},
        {[s['Hero--HasBg']]: !noBg},
        {[s['Hero--NoBg']]: noBg},
        {[s['Hero--Large']]: isLarge},
    );
    return (
        <div className={classes}>
            {!noBg &&
                <div className={s['Hero__Background']}>
                    {!_.isEmpty(image) &&
                        <Image
                            {...image}
                            lazyLoad={false}
                            priority={true}
                        />
                    }

                    {!_.isEmpty(videoBg) &&
                        <div className={s['Hero__Video']}>
                            <Video
                                {...videoBg}
                                isBackground={true}
                                onPlayerReady={onVideoBgReady}
                            />
                        </div>
                    }
                </div>
            }

            <div className={s['Hero__Wrap']}>
                <div className={s['Hero__Container']}>
                    <div className={s['Hero__Content']}>
                        <h1
                            className={s['Hero__Title']}
                            dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                        />

                        {!_.isEmpty(text) &&
                            <p
                                className={s['Hero__Text']}
                                dangerouslySetInnerHTML={{__html: sanitizedText}}
                            />
                        }

                        {(!_.isEmpty(button) || !_.isEmpty(videoModal)) &&
                            <div className={s['Hero__Buttons']}>
                                {!_.isEmpty(button) &&
                                    <Button
                                        {...button}
                                        modifier={buttonModifier}
                                        className={s['Hero__Button']}
                                    />
                                }

                                {!_.isEmpty(videoModal) &&
                                    <Button
                                        className={classNames(s['Hero__Button'], s['Hero__Button--Play'])}
                                        modifier={playModifier}
                                        onClick={() => setModalOpen(true)}
                                    >
                                        <PlayBorder />
                                        {t('hero.watchVideo')}
                                    </Button>
                                }
                            </div>
                        }
                    </div>

                    {!_.isEmpty(videoModal) &&
                        <VideoButton
                            videoPlaying={videoPlaying}
                            clickHandler={() => setVideoPlaying(!videoPlaying)}
                        />
                    }
                </div>
            </div>

            {!_.isEmpty(videoModal) && modalOpen &&
                <div className={s['Hero__Modal']}>
                    <div className={s['Hero__ModalContainer']}>
                        <button
                            className={s['Hero__ModalClose']}
                            onClick={() => setModalOpen(false)}
                        >
                            <span className={'sr-only'}>{t('hero.close')}</span>
                        </button>
                        <div className={s['Hero__ModalContent']}>
                            <Video
                                {...videoModal}
                                className={s['Hero__ModalVideo']}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    button: PropTypes.object,
    image: PropTypes.object,
    videoBg: PropTypes.object,
    videoModal: PropTypes.object,
    hasEntrances: PropTypes.bool,
    isDarkMode: PropTypes.bool,
    isCenter: PropTypes.bool,
    isLarge: PropTypes.bool,
};

Hero.defaultProps = {
    title: '',
    text: '',
    button: null,
    image: null,
    videoBg: null,
    videoModal: null,
    hasEntrances: false,
    isDarkMode: true,
    isCenter: false,
    isLarge: false,
};

const VideoButton = ({videoPlaying, clickHandler}) => {
    const {t} = useTranslation();

    const title = videoPlaying ? t('hero.pauseVideo') : t('hero.playVideo');

    const classes = classNames(
        s['Hero__VideoButton'],
        {[s['Hero__VideoButton--Play']]: !videoPlaying},
        {[s['Hero__VideoButton--Pause']]: videoPlaying},
    );

    return (
        <button
            className={classes}
            onClick={() => clickHandler()}
        >
            {title}
        </button>
    );
};

VideoButton.propTypes = {
    videoPlaying: PropTypes.bool,
    clickHandler: PropTypes.func,
};

VideoButton.defaultProps = {
    videoPlaying: false,
    clickHandler: null,
};

export default Hero;
