import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import classNames from 'classnames';
import CardEntrance from 'Components/CardEntrance';
import s from './Entrances.module.scss';

const Entrances = ({items, isSmall, isOverlap}) => {
    const {t} = useTranslation();

    const width = !isSmall || items.length < 4 ? 'Third' : 'Fourth';

    const classes = classNames(
        s['Entrances'],
        {[s['Entrances--Large']]: !isSmall},
        {[s['Entrances--Small']]: isSmall},
        {[s['Entrances--Overlap']]: isOverlap},
        s[`Entrances--${width}`],
    );
    return (
        <nav className={classes} aria-label={t('entrances.title')}>
            <h2 className={'sr-only'}>{t('entrances.title')}</h2>
            <ul className={s['Entrances__List']}>
                {items.map((item, index) => (
                    <li className={s['Entrances__Item']} key={index}>
                        <CardEntrance {...item} isSmall={isSmall} />
                    </li>
                ))}
            </ul>
        </nav>
    );
};

Entrances.propTypes = {
    items: PropTypes.array.isRequired,
    isSmall: PropTypes.bool,
    isOverlap: PropTypes.bool,
};

Entrances.defaultProps = {
    items: [],
    isSmall: false,
    isOverlap: false,
};

export default Entrances;
